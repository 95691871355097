import './styles.scss';
import React, {memo, useState, useRef, useEffect} from 'react';
import Slider from "react-slick";

import {
    DataRoomAboutScreen1,
    DataRoomAboutScreen2,
    DataRoomAboutScreen3,
    DataRoomAboutScreen4
} from "../../images";

const DataRoomAbout = () => {

    const [width, setWidth] = useState<number>(0);

    function handleWindowSizeChange() {
        console.log(window.innerWidth);
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        handleWindowSizeChange();
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const sliderRef = useRef();

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '15px'
    };

    const tabs = [
        '1. Big library of our own courses and books retellings in 15 min',
        '2. Task and goals formation from education process',
        '3. Get the most important parts of courses for revision',
        '4. Educational support Chat GPT'
    ];

    const slides = [
        DataRoomAboutScreen1,
        DataRoomAboutScreen2,
        DataRoomAboutScreen3,
        DataRoomAboutScreen4,
    ];

    const [activeTab, setActiveTab] = useState(0);
    // const [activeSlide, setActiveSlide] = useState();

    const handleTabClick = (index:number) => {
        setActiveTab(index);
        sliderRef.current.slickGoTo(index);
    }

    return (
        <section className="dataRoomAboutPage">
            <div className="dataRoomContainer">
                {(width < 768) ? (
                    <div className="dataRoomAboutPageBox">
                        <h2 className="dataRoomAboutPageMobileTitle">The most important</h2>
                        <div className="dataRoomAboutPageInfo">
                            <h2 className="dataRoomAboutPageTitle">AEER Platform Inc.</h2>
                            <p className="dataRoomAboutPageDescription">is an <span>Ukrainian</span> start-up and the creator of educational marketplace - FeelGrow. </p>
                            <p className="dataRoomAboutPageDescription">In <span>FeelGrow</span> we offer fully personalized learning experience based on four pillars:</p>
                        </div>
                        <div className="dataRoomAboutPageSlider">
                            <Slider 
                                ref={sliderRef} {...settings}
                                beforeChange={(currentSlide, nextSlide) => {
                                    setActiveTab(nextSlide);
                                }} >
                                {
                                    slides.map((data, index) => (
                                        <div className="dataRoomAboutPageSlide" key={`slide-${index}`}>
                                            <div className="dataRoomAboutPageSlideImage">
                                                <img src={data} alt="" />
                                            </div>
                                        </div>
                                    ))
                                }
                            
                            </Slider>
                        </div>
                        <div className="dataRoomAboutPageTabs">
                            {tabs.map((data, index) => (
                                <div className={`dataRoomAboutPageTab ${(activeTab === index) && 'active'}`}
                                    key={`tab-${index}`}
                                    onClick={() => handleTabClick(index)}>{data}</div>
                            ))}
                            <div className="dataRoomAboutPageTabMobile dataRoomAboutPageTabMobile_1"></div>
                            <div className="dataRoomAboutPageTabMobile dataRoomAboutPageTabMobile_2"></div>
                            <div className="dataRoomAboutPageTabMobile dataRoomAboutPageTabMobile_3"></div>
                        </div>
                    </div>
                ) : (
                    <div className="dataRoomAboutPageBox">
                        <div className="dataRoomAboutPageInfo">
                            <h2 className="dataRoomAboutPageTitle">AEER Platform Inc.</h2>
                            <p className="dataRoomAboutPageDescription">is an <span>Ukrainian</span> start-up and the creator of educational marketplace - FeelGrow. </p>
                            <p className="dataRoomAboutPageDescription">In <span>FeelGrow</span> we offer fully personalized learning experience based on four pillars:</p>
                            <div className="dataRoomAboutPageTabs">
                                {tabs.map((data, index) => (
                                    <div className={`dataRoomAboutPageTab ${(activeTab === index) && 'active'}`}
                                        key={`tab-${index}`}
                                        onClick={() => handleTabClick(index)}>{data}</div>
                                ))}
                            </div>
                        </div>
                        <div className="dataRoomAboutPageSlider">
                            <Slider 
                                ref={sliderRef} {...settings}
                                beforeChange={(currentSlide, nextSlide) => {
                                    setActiveTab(nextSlide);
                                }} >
                                {
                                    slides.map((data, index) => (
                                        <div className="dataRoomAboutPageSlide" key={`slide-${index}`}>
                                            <div className="dataRoomAboutPageSlideImage">
                                                <img src={data} alt="" />
                                            </div>
                                        </div>
                                    ))
                                }
                            
                            </Slider>
                        </div>
                    </div>
                )}
                
            </div>
        </section>
    )
}

export default memo(DataRoomAbout);